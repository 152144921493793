import React, { Children } from "react"
import PropTypes, { InferProps } from "prop-types"
import SEO from "./seo"

const Page = (props: InferProps<typeof propTypes>) => {
  return (
    <>
      <SEO
        title={props.title}
        description={props.description}
        meta={props.meta}
      />
      {props.children}
    </>
  )
}

const propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

Page.propTypes = propTypes

export default Page
