import React from "react"
import { graphql, Link, PageProps } from "gatsby"
import Page from "../components/common/page"
import Header from "../components/header"
import Footer from "../components/footer"
import { useTranslation } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"
const DataProtectionNoticePage = (props: PageProps) => {
  const { t } = useTranslation()
  const { language } = useI18next();

  const excericeRightsFormUrl = language == 'en' ? '/exercise_of_rights_form_en.docx' : '/exercise_of_rights_form_el.docx'
  return (
    <Page title="Data Protection Notice">
      <Header />
      <div className="block lg:flex lg:flex-row justify-center">
        <div className="w-full lg:w-1/2 px-5 lg:px-1">
          <p className="text-secondary text-2xl lg:text-4xl leading-10 mt-4">
            <strong>{t("data_protection_title")}</strong>
          </p>
          <p className="mt-3 text-justify">{t("data_protection_subtitle")}</p>
          <p className="mt-3 text-justify">{t("data_protection_text")}</p>
          <p className="mt-3 text-justify">{t("data_protection_contact")}</p>
          <ul className="list-inside list-disc my-3">
            <li>
              email:{" "}
              <a
                className="text-secondary"
                href="mailto:contact@skills4gigs.com"
              >
                contact@skills4gigs.com
              </a>
              .
            </li>
            <li>{t("letter")}</li>
          </ul>
          <p className="my-3 text-left text-secondary font-bold">
            <strong>{t("data_protection_title_1")}</strong>
          </p>
          <p>{t("data_protection_title_1_text")}</p>
          <p className="my-3 text-left text-secondary font-bold">
            <strong>{t("data_protection_title_2")}</strong>
          </p>
          <p>{t("data_protection_title_2_text")}</p>
          <p className="my-3 text-left text-secondary font-bold">
            <strong>{t("data_protection_title_3")}</strong>
          </p>
          <p>{t("data_protection_title_3_text")}</p>
          <p className="my-3 text-left text-secondary font-bold">
            {t("data_protection_title_4")}
          </p>
          <p>{t("data_protection_title_4_text")}</p>
          <p className="my-3 text-left text-secondary font-bold">
            <strong>{t("data_protection_title_5")}</strong>
          </p>
          <p className="mt-3">{t("data_protection_title_5_text_0")}</p>
          <ul className="list-inside list-disc my-3">
            <li>{t("data_protection_title_5_text_1")}</li>
            <li> {t("data_protection_title_5_text_2")}</li>
            <li>{t("data_protection_title_5_text_3")}</li>
            <li>{t("data_protection_title_5_text_4")}</li>
            <li>{t("data_protection_title_5_text_5")}</li>
            <li>{t("data_protection_title_5_text_6")}</li>
          </ul>
          <p className="my-3 text-left text-secondary font-bold">
            {t("data_protection_title_6")}
          </p>
          <p className="mt-3">{t("data_protection_title_6_text_1")}</p>
          <ul className="list-inside list-disc my-3">
            <li> {t("data_protection_title_6_text_2")}</li>
            <li> {t("data_protection_title_6_text_3")}</li>
            <li>{t("data_protection_title_6_text_4")}</li>
            <li>{t("data_protection_title_6_text_5")}</li>
          </ul>
          <p className="my-3 text-left text-secondary font-bold">
            {t("data_protection_title_7")}
          </p>
          <p>{t("data_protection_title_7_text_1")}</p>
          <p className="my-3 text-left text-secondary font-bold">
            {t("data_protection_title_8")}
          </p>
          <p className="mt-3">{t("data_protection_title_8_text_1")}</p>
          <ul className="list-inside list-disc my-3">
            <li>{t("data_protection_title_8_text_2")}</li>
            <li>{t("data_protection_title_8_text_3")}</li>
            <li>{t("data_protection_title_8_text_4")}</li>
            <li>{t("data_protection_title_8_text_5")}</li>
            <li>{t("data_protection_title_8_text_6")}</li>
            <li>{t("data_protection_title_8_text_7")}</li>
          </ul>
          <p className="my-3 text-left text-secondary font-bold">
            {t("data_protection_title_9")}
          </p>
          <p className="mt-3">{t("date_protection_title_9_text")}</p>
          <p className="my-3 text-left text-secondary font-bold">
            {t("data_protection_title_10")}
          </p>
          <p className="mt-3">
            {t("data_protection_title_10_text")}{" "}
            <u>
              <Link
                className="py-6 mr-3 font-bold text-secondary"
                to="/cookies"
              >
                Cookies
              </Link>
            </u>
          </p>
          <p className="my-3 text-left text-secondary font-bold">
            {t("data_protection_title_11")}
          </p>
          <p className="mt-3">{t("data_protection_title_11_text")}</p>
          <p className="my-3 text-left text-secondary font-bold">
            <strong> {t("data_protection_title_12")}</strong>
          </p>
          <ul className="list-inside list-disc my-3">
            <li>{t("data_protection_title_12_text_1")}</li>
            <li>{t("data_protection_title_12_text_2")}</li>
            <li>{t("data_protection_title_12_text_3")}</li>
            <li>{t("data_protection_title_12_text_4")}</li>
            <li>{t("data_protection_title_12_text_5")}</li>
            <li>{t("data_protection_title_12_text_6")}</li>
            <li>{t("data_protection_title_12_text_7")}</li>
            <li>{t("data_protection_title_12_text_8")}</li>
          </ul>
          <p className="my-3 text-left text-secondary font-bold">
            <strong> {t("data_protection_title_13")}</strong>
          </p>
          <p className="mt-3">
            {t("data_protection_title_13_text_1")}
            <strong>
              {" "}
              <a
                className="text-secondary"
                href={excericeRightsFormUrl}
                download
              >
                {" "}
                {t("here")}{" "}
              </a>
            </strong>
            {t("data_protection_title_13_text_2")}{" "}
            <u>
              {" "}
              <a
                className="text-secondary"
                href="mailto:contact@skills4gigs.com"
              >
                contact@skills4gigs.com
              </a>
            </u>
            .
          </p>
          <p className="my-3 text-left text-secondary font-bold">
            {t("data_protection_title_14")}
          </p>
          <p className="mt-3">
            {t("data_protection_title_14_text")}{" "}
            <a className="text-secondary" href="mailto:contact@skills4gigs.com">
              contact@skills4gigs.com
            </a>
            .
          </p>
          <p className="my-3 text-left text-secondary font-bold">
            {t("data_protection_title_15")}
          </p>
          <p className="mt-3">{t("data_protection_title_15_text")}</p>
          <p className="my-3 text-left text-secondary font-bold">
            {t("data_protection_title_16")}
          </p>
          <p className="mt-3">{t("data_protection_title_16_text")}</p>

          <p className="my-3 text-left text-secondary font-bold">
            <strong>{t("data_protection_title_17")}</strong>
          </p>
          <p className="mt-3">
            {t("data_protection_title_17_text")}{" "}
            <a className="text-secondary" href="http://www.dpa.gr/">
              www.dpa.gr
            </a>
          </p>
          <p className="my-3 text-left text-secondary font-bold">
            <strong>{t("data_protection_title_18")}</strong>
          </p>
          <p className="mt-3">
            {t("data_protection_title_18_text_1")} (
            <a className="text-secondary" href="https://skills4gigs.com/">
              skills4gigs.com
            </a>
            ) {t("data_protection_title_18_text_2")}
          </p>
        </div>
      </div>
      <Footer />
    </Page>
  )
}

export default DataProtectionNoticePage
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
